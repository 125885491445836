@import "bootstrap";
@import "../../styles/variables";
@import "../../styles/Font";

.blocAutreCollections {
    // background-color: blueviolet;
    position: relative;
    height: 90px;
    width: 470px;
    left: 50%;
    transform: translate(-50%, 0);
    & .blocTextAutreCollections {
        position: absolute;
        top: 50%;
        transform: translate(0, -50%);
        font-size: 1.35em;
        font-weight: bolder;
        color: #2a292a;
    }
    & .blocBarreVerticale {
        position: absolute;
        left: 41%;
        transform: translate(-50, 0);
        height: 100%;
        width: 2px;
        // background-color: #bcc4d6;
        z-index: 100;
    }
    & .texte1 {
        left: 240px;
        top: 6px;
        margin-top: -6px;
    }
    & .texte2 {
        left: 240px;
        top: 56px;
    }

    & .texte {
        position: absolute;
        background-color: #fff;
        width: 200px;
        height: 35px;
        padding: 5px;
        padding-left: 12px;
        cursor: pointer;
        &:hover {
            background-color: #ccc;
            .triangle {
                border-color: #ccc transparent transparent transparent !important;
            }
        }
        & .contenu {
            position: relative;
            font-weight: bolder;
            & .triangle {
                position: absolute;
                right: -30px;
                top: -5px;
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 35px 25px 0 0;
                border-color: #fff transparent transparent transparent;
                float: right;
            }
        }
    }
}

.blocAutreCollectionMobile {

    & .texte {
        // position: absolute;
        background-color: #fff;
        width: 200px;
        height: 35px;
        padding: 5px;
        padding-left: 12px;
        cursor: pointer;
        &:hover {
            background-color: #ccc;
            .triangle {
                border-color: #ccc transparent transparent transparent !important;
            }
        }
        & .contenu {
            position: relative;
            font-weight: bolder;
            & .triangle {
                position: absolute;
                right: -30px;
                top: -5px;
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 35px 25px 0 0;
                border-color: #fff transparent transparent transparent;
                float: right;
            }
        }
    }
}
.bleu {
    color: #2b4998 !important;
}
.bg-bleu {
    background-color: #2b4998 !important;
}
.rouge {
    color: #c31a1f !important;
}
.bg-rouge {
    background-color: #c31a1f !important;
}
.jaune {
    color: #dca415 !important;
}
.bg-jaune {
    background-color: #dca415 !important;
}
