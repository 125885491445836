@import "bootstrap";
@import "../../styles/variables";
@import "../../styles/Font";

.video-container {
	overflow: hidden;
	padding-bottom: 56.25%;
	position: relative;
	height: 0;
}

.video-container iframe {
	left: 0;
	top: 0;
	height: 100%;
	width: 100%;
	position: absolute;
}

.listeLivre {
	& .livreBloc {
		margin: 1em;
		& .bloc {
			position: relative;
			background-color: #aaa;
			// background-color: #e5e9ea;
			padding: 20px;
			text-align: center;
			a:hover {
				text-decoration: none;
				& .ombre {
					opacity: 1;
					transition: all 0.3s ease-in-out;
				}
				& .texteLien {
					opacity: 1;
					transition: all 0.3s ease-in-out;
					font-size: 1.2em;
					font-weight: bolder;
				}
			}
			img {
				max-height: 300px;

				-webkit-box-shadow: 10px 10px 7px -7px rgba(0, 0, 0, 0.63);
				-moz-box-shadow: 10px 10px 7px -7px rgba(0, 0, 0, 0.63);
				box-shadow: 10px 10px 7px -7px rgba(0, 0, 0, 0.63);
			}
			& .ombre {
				background-color: rgba(0, 0, 0, 0.5);
				position: absolute;
				opacity: 0;
				top: 0px;
				left: 0px;
				z-index: 100;
				width: 100%;
				height: 100%;
			}

			& .texteLien {
				position: absolute;
				opacity: 0;
				top: 50%;
				font-size: 1.5em;
				left: 0px;
				z-index: 101;
				width: 100%;
				color: #fff;

				span {
					border: 1px solid #fff;
					padding: 20px 25px 20px 25px;
				}
			}
		}
		& .texteBloc {
			height: 70px;
			color: #fff !important;

			&.rouge {
				background-color: #be0503;
			}
			&.orange {
				background-color: #daa404;
			}
			&.jaune {
				background-color: #daa404;
			}
			&.bleu {
				background-color: #17488d;
			}
			&.noir {
				background-color: #252525;
			}
			padding-left: 20px;

			& .titre {
				padding-top: 3px;
				font-weight: bolder;
				font-style: italic;
				font-size: 1.1em;
			}
			& .sousTitre {
				font-size: 0.9em;
				line-height: 1.2em;
			}
		}
	}
}

.blocHome {
	.contenu-h1,
	.contenu-h2 {
		font-size: 1.8em;
	}
}

.gridSlide {
	position: relative;
	clear: both;
	margin: 0 auto;
	// padding: 1em 0 4em;
	// max-width: 1000px;
	list-style: none;
	text-align: center;
}

/* Common style */
.gridSlide figure {
	position: relative;
	float: left;
	overflow: hidden;
	background: #3085a3;
	text-align: center;
	cursor: pointer;
}

.gridSlide figure img {
	position: relative;
	display: block;
	min-height: 100%;
	max-width: 100%;
	opacity: 0.8;
}

.gridSlide figure figcaption {
	padding: 2em;
	color: #fff;
	text-transform: uppercase;
	font-size: 1.25em;
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
}

.gridSlide figure figcaption::before,
.gridSlide figure figcaption::after {
	pointer-events: none;
}

.gridSlide figure figcaption,
.gridSlide figure figcaption > a {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

/* Anchor will cover the whole item by default */
/* For some effects it will show as a button */
.gridSlide figure figcaption > a {
	z-index: 1000;
	text-indent: 200%;
	white-space: nowrap;
	font-size: 0;
	opacity: 0;
}

.gridSlide figure h2 {
	word-spacing: -0.15em;
	font-weight: 300;
}

.gridSlide figure h2 span {
	font-weight: 800;
}

.gridSlide figure h2,
.gridSlide figure p {
	margin: 0;
}

.gridSlide figure p {
	letter-spacing: 1px;
	font-size: 68.5%;
}

/* Individual effects */

/*-----------------*/
/***** Goliath *****/
/*-----------------*/

figure.effect-goliath {
	background: #df4e4e;
}

figure.effect-goliath img,
figure.effect-goliath h2 {
	-webkit-transition: -webkit-transform 0.35s;
	transition: transform 0.35s;
}

figure.effect-goliath img {
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
}

figure.effect-goliath h2,
figure.effect-goliath p {
	position: absolute;
	bottom: 0;
	left: 0;
	padding: 30px;
}

figure.effect-goliath p {
	text-transform: none;
	font-size: 90%;
	opacity: 0;
	-webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
	transition: opacity 0.35s, transform 0.35s;
	-webkit-transform: translate3d(0, 50px, 0);
	transform: translate3d(0, 50px, 0);
}

figure.effect-goliath:hover img {
	-webkit-transform: translate3d(0, -80px, 0);
	transform: translate3d(0, -80px, 0);
}

figure.effect-goliath:hover gh2 {
	-webkit-transform: translate3d(0, -100px, 0);
	transform: translate3d(0, -100px, 0);
}

figure.effect-goliath:hover p {
	opacity: 1;
	-webkit-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);
}

/*---------------*/
/***** Ming *****/
/*---------------*/

// figure.effect-ming {
// 	background: #030c17;
// }

// figure.effect-ming p {
// 	padding: 1em;
// 	opacity: 0;
// 	transition: opacity 1s, transform 0.2s;
// 	// transition: opacity 0.35s, transform 0.35s;
// 	transform: scale(1.5);
// }

// figure.effect-ming:hover figcaption::before,
// figure.effect-ming:hover p {
// 	opacity: 1;
// 	transform: scale3d(1, 1, 1);
// }

// figure.effect-ming:hover img {
// 	opacity: 0.7;
// }

figure.effect-ming {
	background: #030c17;
	-ms-transform: translate(-50%, 0);
	-webkit-transform: translate(-50%, 0);
	-moz-transform: translate(-50%, 0);
	-o-transform: translate(-50%, 0);
}

figure.effect-ming .icone {
	transition: width 0.2s;
}

figure.effect-ming:hover .icone {
	width: 100px !important;
}
