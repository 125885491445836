@import "variables";
// @import "bootstrap/scss/functions";
// @import "bootstrap/scss/variables";
// @import "bootstrap/scss/mixins";

@import "bootstrap";

body {
	// font-family: Trebuchet MS, Lucida Grande, Lucida Sans Unicode, Lucida Sans,
	// 	Tahoma, sans-serif !important;
	font-family: "Alegreya Sans", sans-serif, Trebuchet MS, Lucida Grande,
		Lucida Sans Unicode, Lucida Sans, Tahoma, sans-serif !important;
	font-weight: 300 !important;
	color: $gris3 !important;
}

p {
	text-align: justify;
	font-size: 1.1em;
}

.texte {
	font-size: 1.2em;
}

.lien {
	color: $gris3 !important;
	font-weight: 400;
	// color: $green !important;
	text-decoration: none;
	background-color: transparent;
}

.liste li {
	padding-left: 45px;
	padding-top: 0px;
	background: url("../resources/icons/fleche jaune-essai.svg") no-repeat 7px -2px
		transparent;
	list-style-type: none;
	margin: 0;
	vertical-align: middle;
	font-size: 1.2em;
}

.rouge li {
	background: url("../resources/icons/flecheLiRouge.svg") no-repeat 7px -2px transparent !important;
}
.orange li {
	background: url("../resources/icons/flecheLiOrange.svg") no-repeat 7px -2px transparent !important;
}
.jaune li {
	background: url("../resources/icons/flecheLiJaune.svg") no-repeat 7px -2px transparent !important;
}
.bleu li {
	background: url("../resources/icons/flecheLiBleu.svg") no-repeat 7px -2px transparent !important;
}

.liste {
	padding-left: 0px;
}

.no-gutters {
	padding-left: 0px !important;
	padding-right: 0px !important;
}

.module-medium {
	padding: 75px 0px;
}

.module-small {
	padding: 40px 0;
}

.App h1,
.App h2,
.App h3,
.App h4,
.App h5,
.App h6 {
	// color: $texte-noir;
	// letter-spacing: 1px ;
	// font-family: "Lato";
	// font-weight: 400;
	// font-size: 1.5em;
}

.green {
	color: $green;
}

.App h1 {
	color: #ffffff;
}

.scroll-up a:hover,
.scroll-up a:active {
	opacity: 1;
	color: #000;
}

.scroll-up a {
	background: #fff;
	display: block;
	height: 28px;
	width: 28px;
	text-align: center;
	line-height: 28px;
	font-size: 14px;
	color: #000;
	opacity: 0.6;
	border-radius: 2px;
}

.scroll-up {
	position: fixed;
	display: none;
	bottom: 7px;
	right: 7px;
	z-index: 999;
}

// iframe {
// 	border: 0px !important;
// }

// @media (min-width: 1600px) {
// 	.App .col-xxl-2,
// 	.App .col-xxl-10 {
// 		position: relative;
// 		width: 100%;
// 		min-height: 1px;
// 		padding-right: 15px;
// 		padding-left: 15px;
// 	}

// 	.App .col-xxl-2 {
// 		flex: 0 0 16.66%;
// 		max-width: 16.66%;
// 	}

// 	.App .col-xxl-10 {
// 		flex: 0 0 83.33%;
// 		max-width: 83.33%;
// 	}
// }

// **********************************************************************************
//  TABLEAUX DE PROGRAMMES

.tdResponsive {
	height: 150px;
	// width: 300px;
	width: 92%;
	vertical-align: top;
}

.colos {
	height: 150px;
	width: 800px;
	vertical-align: top;
}

.lieu {
	height: 60px;
	background-color: #414042;
	font-size: 1.75em;
	font-weight: normal;
}

.ligne {
	width: 63px !important;
	// margin-left: 10px;
	// margin-right: 10px;
}

.ligneResponsive {
	width: 55px !important;
}

.typeCamp {
	background-color: #bde6e4;
	color: #11a49d;
	vertical-align: top;
}

// **************************************************************************************

// CSS DE MEDIASEARCH A METTRE DANS LE MODULE REACT-MITIM

.category a,
.category a:hover {
	color: $grey;
	font-size: 18px;
}

.category a.active {
	color: #b01125;
}

.categories-navbar div:first-child.category::before {
	border-left: none;
}

.category::before {
	content: "";
	border: none;
	border-left: 1px solid $grey;
	line-height: 36px;
	position: relative;
	right: 15px;
}

div.categories-navbar {
	background-color: #ececec;

	@include media-breakpoint-up(md) {
		height: 100px;
	}
}

div.filter-list-continue {
	background-color: $gris2;
}

div.filter-list {
	@include media-breakpoint-up(md) {
		min-height: 800px;
	}

	background-color: $gris2;
}

div.filter {
	background-color: white;
}

div.filter h5 {
	text-transform: uppercase;
	font-size: 16px;
}

div.filter .filter-open {
	background-color: white;
	color: $rouge;
}

div.filter .filter-close {
	// background-color: $rouge;
	background-color: #3f3e40;
	color: white;
}

div.filter-items {
	overflow-x: hidden;
	overflow-y: auto;
	max-height: 200px;
}

div.filter-items-hide {
	display: none;
}

div.filter-items-checkbox-show {
	display: flex;
}

div.result div.img-zoom .async-img-result,
div.result div.img-zoom {
	width: 200px;
	height: 200px;
	@include media-breakpoint-down(sm) {
		& {
			width: 150px;
			height: 150px;
		}
	}
}
div.result div.img-zoom {
	overflow: hidden;
}

div.result div.result-img {
	transition: all 0.3s ease-in-out;
}

div.result:hover div.result-img {
	// transform: scale(1.1);
}

div.result a:hover {
	text-decoration: none;
}

div.result h6.result-title {
	color: $grey;
	font-weight: bold;
}

div.result span.result-infos {
	font-size: small;
}

div.result div.media-icons svg {
	font-size: 28px;
}

div.result-number {
	color: $grey;
	padding-top: 15px;
}

div.result img.result-list-img {
	max-width: 75px;
}

.bg-grey-light {
	background-color: $grey-light;
}

.bg-blue-light {
	background-color: $blue-light;
}

.caroussel-prev {
	// width: 20px;
	// height: 20px;
	// position: absolute;
	// padding-top: 8%;
	// margin-left: -59px;
	left: -25px;
	font-size: 0;
	line-height: 0;
	top: 50%;
	width: 20px;
	height: 20px;
	-webkit-transform: translate(0, -50%);
	-ms-transform: translate(0, -50%);
	transform: translate(0, -50%);
	cursor: pointer;
	color: transparent;
	border: none;
	outline: 0;
	background: 0 0;
	position: absolute;
	display: block;
	padding: 0;
	text-transform: none;
	overflow: visible;
	box-sizing: border-box;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	-webkit-touch-callout: none;
	-khtml-user-select: none;
	-ms-touch-action: pan-y;
	touch-action: pan-y;
	-webkit-tap-highlight-color: transparent;
}

.caroussel-next {
	right: -39px;
	width: 20px;
	height: 20px;
	position: absolute;
	margin-top: -195px;
	// margin-top: -153%;
	margin-right: -2px;
}

.lienSlide {
	color: $gris3 !important;
	text-decoration: none !important;
}

.lienSlide .slideBlockTypeMedia:hover {
	// box-shadow: 8px 8px 12px #aaa;
	filter: brightness(110%);
	margin-top: "-100px" !important;
}
