@import "variables";
// LES BACKGROUND COLOR
.bg-grey-light {
	background-color: $grey-light;
}

.bg-blue-light {
	background-color: $blue-light;
}

.bg-blue-fonce {
	background-color: $blue-fonce;
}

.bg-green3 {
	background-color: $green3;
}

.bg-green {
	background-color: $green;
}

.bg-grey2 {
	background-color: $grey2;
}

// NOUVELLES *****************
.bg-jaune {
	// background-color: $orange;
	background-color: $bleu;
}

.bg-bleu {
	background-color: $bleu;
}

.bg-gris1 {
	background-color: $gris;
}

.bg-gris {
	background-color: $gris;
}

.bg-gris2 {
	background-color: $gris2;
}

.bg-gris3 {
	background-color: $gris3;
}

.bg-gris4 {
	background-color: $gris4;
}

.bg-gris5 {
	background-color: $gris5;
}
.bg-gris8 {
	background-color: $gris8;
}

// ********************************************************
// COULEURS DE TEXTE

.text-jaune {
	// color: #f2c717 !important;
	color: $bleu !important;
}

.text-grey2 {
	color: $grey2 !important;
}

.text-black {
	// color: #000000 !important;
	// color: $texte-noir !important;
	// color: #222 !important;
	color: $texte-noir !important;
}
.text-noir {
	// color: #000000 !important;
	// color: $texte-noir !important;
	// color: #222 !important;
	color: $texte-noir !important;
}

.text-black2 {
	// color: #000000 !important;
	color: $texte-noir !important;
}

// ****************************************************************

.text-menu {
	// letter-spacing: 1px !important;
	font-family: "Open sans";
	font-weight: 400 !important;
}

.ft-open {
	// font-family: "Open sans";
	font-family: 'Jost', sans-serif;
	font-weight: 200 !important;
}

.ft-open-sans {
	font-family: "Open sans";
	// font-family: 'Jost', sans-serif;
	font-weight: 400 !important;
}

.ft-lato-regular {
	font-family: "Lato", "sans serif" !important;
	font-weight: 400;
	//   letter-spacing: 1px;

	font-family: "Open sans";
	font-weight: 400 !important;
}

.ft-lato-light {
	letter-spacing: 1px !important;
	font-family: "Lato";
	font-weight: 300 !important;
}

.ft-lato-regular {
	// letter-spacing: 1px !important;
	font-family: "Lato";
	font-weight: 400 !important;
}

.ft-alegreya-sans-light {
	font-family: "Alegreya Sans", sans-serif;
	font-weight: 300 !important;
}

.ft-alegreya-sans-medium {
	font-family: "Alegreya Sans", sans-serif;
	font-weight: 500 !important;
}

.ft-alegreya-sans-regular {
	font-family: "Alegreya Sans", sans-serif;
	font-weight: 400 !important;
}

.ft-league-script-regular {
	font-family: "League Script", cursive;
}

.ft-parisienne {
	font-family: "Parisienne", cursive;
}

.ft-roboto-mono {
	font-family: "Roboto Mono", monospace;
}

.ft-ubuntu {
	font-family: "Ubuntu", sans-serif;
}

.ft-ubuntu-light {
	font-family: "Ubuntu", sans-serif;
	font-weight: 300 !important;
}

.contenu-h1 {
	font-family: "Lato";
	font-weight: 300 !important;
	font-size: 2em;
	color: $gris3 !important;
	text-align: center;
}

.contenu-h2 {
	font-family: "Parisienne", cursive;
	color: $gris3 !important;
	text-align: center;
	font-size: 2.5em;
}

.contenu-h3 {
	font-family: "Parisienne", cursive;
	color: $gris3 !important;
	text-align: center;
}

.titreEncart {
	font-family: "Lato";
	font-weight: 300 !important;
	font-size: 1.5em;
	color: $gris3 !important;
	text-align: center;
}

.bg-bleu-slide {
	background-color: $bleu-slide;
}
.bg-orange-slide {
	background-color: $orange-slide;
}
.bg-jaune-slide {
	background-color: $jaune-slide;
}
