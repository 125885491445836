.slideTemplate1 {
    &.nosCollections {
        background-color: transparent;
        border-color: #eb7061;
        font-size: 1.5em;
        color: #fff;
        border: 1px solid #fff;
        padding: 20px 60px 20px 60px;
        transition: all 0.3s ease-in-out;
        &:hover {
            background-color: #fff;
            color: #111;
        }
    }
}

.logoTwitter:hover {
    & .Twitter3 {
        fill: #1da1f2;
        transition: all 0.3s ease-in-out;
    }
}
.logoYoutube:hover {
    & .youtube3 {
        fill: #be0503;
        transition: all 0.3s ease-in-out;
    }
}
