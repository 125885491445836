@import "bootstrap";
@import "../../styles/variables";
@import "../../styles/Font";

.module-small {
	background-color: $gris;
	font-family: Trebuchet MS, Lucida Grande, Lucida Sans Unicode, Lucida Sans,
		Tahoma, sans-serif;
	font-size: 12px;
	line-height: 1.8;
}

.footer {
	padding: 10px 0;
	font-size: 12px;
	line-height: 1.8;
}

.module-small ul {
	padding-left: 0px;
}

.module-small ul li {
	list-style: none;
}

.module-small h5 {
	margin-bottom: 0px;
	font-weight: bold;
}

.module-small a:hover {
	text-decoration: underline;
}

.App .module-small h5,
.App .module-small a {
	color: $grey;
}

.module-small .row {
	grid-auto-flow: column;
}

.footer,
.footer a {
	// background-color: #fff;
	// background-color: $color-menu;
	color: #000;
}

@media (min-width: 1310px) {
	.footer .container-fluid {
		padding-left: 100px;
	}
}

@include media-breakpoint-up(md) {
	.footer .col-md-auto a:before {
		content: '-';
		position: absolute;
		left: -2px;
		top: 0px;
	}
}

.social img {
	height: 40px;
}

@include media-breakpoint-down(sm) {
	.w-1000 {
		width: 100% !important;
	}
}

.social .share-links.share-links-show {
	display: initial;
}

.social .share-links {
	display: none;
}

.social .share-links img {
	height: 25px;
	margin-top: 7px;
}

.cursor-pointer {
	cursor: pointer;
}
