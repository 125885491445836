@import "variables";

.Media .paroles {
	& b,
	& strong {
		color: $green;
	}
}

.bg-green-to-blue {
	background-color: $green;
	// background-image: linear-gradient(
	// 	to bottom,
	// 	$green,
	// 	$green 70%,
	// 	$blue 70%,
	// 	$blue 100%
	// );
}

.bg-blue {
	background-color: $blue;
	color: white;
}

.bg-white {
	background-color: white;
	color: $grey;
}

.Media .title {
	text-transform: uppercase;
}

.Media h4.white {
	color: white;
}

.Media h4.green {
	color: $green;
}

.Media .fichiersATelecharger li {
	list-style: none;

	a {
		color: white;
		// background-image: url('../resources/icons/fleche-blanche-fond-bleu.png');
		background-size: 16px 16px;
		background-repeat: no-repeat;
		padding-left: 33px;
	}
}

.player tr:hover {
	cursor: auto;
	color: $grey-light;
	// color: $grey-light;

	&.ligne {
		color: #fff;
		// color: $green;
		background-color: #7cb4d7;
		// background-color: $grey-light;

		td.rang {
			background-image: url("../resources/albumCd/play3.svg");
			background-repeat: no-repeat;
			background-size: 15px 15px;
			background-position-x: 10px;
			background-position-y: 16px;
		}

		margin-left: 50px;
		cursor: pointer;
	}
}

.player tr {
	color: $grey-light;

	&.ligne {
		color: $texte-noir;
	}
}

audio {
	// display: none;
	// background-color: white;
}

.mejs-controls {
	background-image: white !important;
}

.lineSelected {
	color: $green;
	// background-color: $grey-light;

	td.rang {
		background-image: url("../resources/albumCd/play3.svg");
		background-repeat: no-repeat;
		background-size: 15px 15px;
		background-position-x: 10px;
		background-position-y: 16px;
	}

	margin-left: 50px;
	cursor: pointer;
}
