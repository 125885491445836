@import "bootstrap";
@import "../../styles/variables";
@import "../../styles/Font";

.blocCouverture {
	position: relative;
	cursor: pointer;
	&:hover {
		text-decoration: none;
		.ombre {
			opacity: 1;
			transition: all 0.3s ease-in-out;
		}
		.texteLien {
			opacity: 1;
			transition: all 0.3s ease-in-out;
			.ion-ios-search-strong {
				font-size: 50px;
				transition: all 0.3s ease-in-out;
			}
		}
	}
	.ombre {
		background-color: rgba(0, 0, 0, 0.1);
		position: absolute;
		opacity: 0;
		top: 0px;
		left: 0px;
		z-index: 100;
		width: 100%;
		height: 100%;
	}

	.texteLien {
		position: absolute;
		opacity: 0;
		top: 50%;
		left: 50%;
		z-index: 101;
		color: #fff;
		transform: translate(-45%, -50%);
		.ion-ios-search-strong {
			font-size: 3px;
		}
	}
}

.livre {
	.resume {
		.bleu {
			color: blue !important;
		}
	}
	.showMoreText {
		color: #000 !important;
	}
	.imageCouverture {
		-webkit-box-shadow: 10px 10px 7px -7px rgba(0, 0, 0, 0.63);
		-moz-box-shadow: 10px 10px 7px -7px rgba(0, 0, 0, 0.63);
		box-shadow: 10px 10px 7px -7px rgba(0, 0, 0, 0.63);
	}
	.ion-ios-download-outline {
		font-size: 20px;
	}
}

.blocDetail {
	width: 100%;
	position: relative;
	height: 350px;
	.tableauGauche {
		position: absolute;
		width: 46%;
		height: 100%;
		left: 0;
		z-index: 2;
	}
	.barre {
		width: 4px;
		background-color: #000;
		position: absolute;
		width: 1px;
		height: 100%;
		left: 50%;
		z-index: 2;
	}
	.tableauDroit {
		position: absolute;
		width: 46%;
		height: 100%;
		right: 0;
		z-index: 2;
	}
}

.livreTable {
	td,
	th {
		width: 100%;
		padding-top: 0.75rem;
		padding-bottom: 0.75rem;
		vertical-align: top;
		border-bottom: 1px solid #000 !important;
		white-space: nowrap;
		&.nom {
			color: #000;
			font-weight: bolder;
		}
		&.valeur {
			text-align: right;
		}
	}
}
